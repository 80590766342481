import { BUSINESS_ACTIONS } from "../constants/action-types";

const initialState = {
  businesses: [],
  categories: [],
  complaints: [],
  businessCategories: [],
  message: {},
  status: false,
  saveStatus: false,
};

const businessReducer = (state = initialState, action) => {
  switch (action.type) {
    case BUSINESS_ACTIONS.BUSINESS_LOADING:
      return Object.assign({}, state, {
        businesses: [],
        message: "",
        status: false,
      });
    case BUSINESS_ACTIONS.BUSINESS_LOADED:
      return Object.assign({}, state, {
        businesses: action.payload.businesses,
        status: true,
      });
    case BUSINESS_ACTIONS.BUSINESS_ERROR:
      return Object.assign({}, state, {
        businesses: [],
        message: action.payload.message,
        status: false,
      });
    case BUSINESS_ACTIONS.CATEGORIES_LOADING:
      return Object.assign({}, state, {
        categories: [],
        message: "",
        status: false,
      });
    case BUSINESS_ACTIONS.CATEGORIES_LOADED:
      return Object.assign({}, state, {
        categories: action.payload.categories,
        status: true,
      });
    case BUSINESS_ACTIONS.CATEGORIES_ERROR:
      return Object.assign({}, state, {
        categories: [],
        message: action.payload.message,
        status: false,
      });
    case BUSINESS_ACTIONS.BUSINESS_CATEGORIES_LOADING:
      return Object.assign({}, state, {
        businessCategories: [],
        message: "",
        status: false,
      });
    case BUSINESS_ACTIONS.BUSINESS_CATEGORIES_LOADED:
      return Object.assign({}, state, {
        businessCategories: action.payload.businessCategories,
        status: true,
      });
    case BUSINESS_ACTIONS.BUSINESS_CATEGORIES_ERROR:
      return Object.assign({}, state, {
        businessCategories: [],
        message: action.payload.message,
        status: false,
      });
    case BUSINESS_ACTIONS.BUSINESS_SAVE_START:
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case BUSINESS_ACTIONS.BUSINESS_SAVE_SUCCESS:
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case BUSINESS_ACTIONS.BUSINESS_SAVE_ERROR:
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });
    case BUSINESS_ACTIONS.COMPLAINTS_LOADING:
      return Object.assign({}, state, {
        complaints: [],
        message: "",
        status: false,
      });
    case BUSINESS_ACTIONS.COMPLAINTS_LOADED:
      return Object.assign({}, state, {
        complaints: action.payload.complaints,
        status: true,
      });
    case BUSINESS_ACTIONS.COMPLAINTS_ERROR:
      return Object.assign({}, state, {
        complaints: [],
        message: action.payload.message,
        status: false,
      });
    default:
      return state;
  }
};

export default businessReducer;
