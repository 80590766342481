import Business from "../components/Business/business";
import Category from "../components/Category/category";
import BusinessCategory from "../components/BusinessCategory/businessCategory";
import Complaints from "../components/Complaint/complaints";
import Config from "../components/Config/config";
import Users from "../components/Users/users";

export const routes = [
  {
    path: "/pf/users",
    component: Users,
  },
  {
    path: "/pf/business",
    component: Business,
  },
  {
    path: "/pf/category",
    component: Category,
  },
  {
    path: "/pf/business-category",
    component: BusinessCategory,
  },
  {
    path: "/pf/complaints",
    component: Complaints,
  },
  {
    path: "/pf/config",
    component: Config,
  },
];
