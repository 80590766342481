import Axios from "axios";
import { BUSINESS_ACTIONS } from "../constants/action-types";

export const getBusiness = () => {
  return async (dispatch) => {
    dispatch({ type: BUSINESS_ACTIONS.BUSINESS_LOADING });
    await Axios.get("/1.0.0/business", {
      //headers: { Authorization: token },
    })
      .then((response) => {
        dispatch({
          type: BUSINESS_ACTIONS.BUSINESS_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: BUSINESS_ACTIONS.BUSINESS_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};
export const getCategories = () => {
  return async (dispatch) => {
    dispatch({ type: BUSINESS_ACTIONS.CATEGORIES_LOADING });
    await Axios.get("/1.0.0/business/category", {
      //headers: { Authorization: token },
    })
      .then((response) => {
        dispatch({
          type: BUSINESS_ACTIONS.CATEGORIES_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: BUSINESS_ACTIONS.CATEGORIES_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const saveBusiness = (params) => {
  return async (dispatch) => {
    dispatch({ type: BUSINESS_ACTIONS.BUSINESS_SAVE_START });
    await Axios.post("/1.0.0/business", params, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        dispatch({
          type: BUSINESS_ACTIONS.BUSINESS_SAVE_SUCCESS,
        });
      })
      .catch((err) => {
        dispatch({
          type: BUSINESS_ACTIONS.BUSINESS_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const saveCategory = (params) => {
  return async (dispatch) => {
    dispatch({ type: BUSINESS_ACTIONS.BUSINESS_SAVE_START });
    await Axios.post("/1.0.0/business/category", params)
      .then((response) => {
        dispatch({
          type: BUSINESS_ACTIONS.BUSINESS_SAVE_SUCCESS,
        });
      })
      .catch((err) => {
        dispatch({
          type: BUSINESS_ACTIONS.BUSINESS_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const deleteCategory = (id) => {
  return async (dispatch) => {
    dispatch({ type: BUSINESS_ACTIONS.BUSINESS_SAVE_START });
    await Axios.delete("/1.0.0/business/category/" + id)
      .then((response) => {
        dispatch({
          type: BUSINESS_ACTIONS.BUSINESS_SAVE_SUCCESS,
        });
      })
      .catch((err) => {
        dispatch({
          type: BUSINESS_ACTIONS.BUSINESS_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const modifyBusiness = (params) => {
  return async (dispatch) => {
    dispatch({ type: BUSINESS_ACTIONS.BUSINESS_SAVE_START });
    await Axios.put("/1.0.0/business", params, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        dispatch({
          type: BUSINESS_ACTIONS.BUSINESS_SAVE_SUCCESS,
        });
      })
      .catch((err) => {
        dispatch({
          type: BUSINESS_ACTIONS.BUSINESS_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};
export const getBusinessCategories = () => {
  return async (dispatch) => {
    dispatch({ type: BUSINESS_ACTIONS.BUSINESS_CATEGORIES_LOADING });
    await Axios.get("/2.0.0/business/business-category")
      .then((response) => {
        dispatch({
          type: BUSINESS_ACTIONS.BUSINESS_CATEGORIES_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: BUSINESS_ACTIONS.BUSINESS_CATEGORIES_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const createBusinessCategory = (params) => {
  return async (dispatch) => {
    dispatch({ type: BUSINESS_ACTIONS.BUSINESS_SAVE_START });
    await Axios.post("/2.0.0/business/business-category", params, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        dispatch({
          type: BUSINESS_ACTIONS.BUSINESS_SAVE_SUCCESS,
        });
      })
      .catch((err) => {
        dispatch({
          type: BUSINESS_ACTIONS.BUSINESS_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const deleteBusinessCategory = (categoryId) => {
  return async (dispatch) => {
    dispatch({ type: BUSINESS_ACTIONS.BUSINESS_SAVE_START });
    await Axios.delete(`/2.0.0/business/business-category/${categoryId}`)
      .then((response) => {
        dispatch({
          type: BUSINESS_ACTIONS.BUSINESS_SAVE_SUCCESS,
        });
      })
      .catch((err) => {
        dispatch({
          type: BUSINESS_ACTIONS.BUSINESS_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const modifyBusinessCategoryIndex = (params) => {
  return async (dispatch) => {
    dispatch({ type: BUSINESS_ACTIONS.BUSINESS_SAVE_START });
    await Axios.post("/2.0.0/business/business-category-index", params)
      .then((response) => {
        dispatch({
          type: BUSINESS_ACTIONS.BUSINESS_SAVE_SUCCESS,
        });
      })
      .catch((err) => {
        dispatch({
          type: BUSINESS_ACTIONS.BUSINESS_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const modifyBusinessCategory = (params) => {
  return async (dispatch) => {
    dispatch({ type: BUSINESS_ACTIONS.BUSINESS_SAVE_START });
    await Axios.put("/2.0.0/business/business-category", params, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        dispatch({
          type: BUSINESS_ACTIONS.BUSINESS_SAVE_SUCCESS,
        });
      })
      .catch((err) => {
        dispatch({
          type: BUSINESS_ACTIONS.BUSINESS_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const deleteBusiness = (id) => {
  return async () => {
    await Axios.delete("/1.0.0/business/" + id).catch((err) => {
      console.log(err);
    });
  };
};

export const getComplaints = () => {
  return async (dispatch) => {
    dispatch({ type: BUSINESS_ACTIONS.COMPLAINTS_LOADING });
    await Axios.get("/1.0.0/business/complaint", {
      //headers: { Authorization: token },
    })
      .then((response) => {
        dispatch({
          type: BUSINESS_ACTIONS.COMPLAINTS_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: BUSINESS_ACTIONS.COMPLAINTS_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const deleteComplaint = (id) => {
  return async () => {
    await Axios.delete("/1.0.0/business/complaint/" + id).catch((err) => {
      console.log(err);
    });
  };
};
export const deleteLogo = (id) => {
  return async () => {
    await Axios.delete("/1.0.0/business/logo/" + id).catch((err) => {
      console.log(err);
    });
  };
};
export const deleteKeyPartnerPicture = (id) => {
  return async () => {
    await Axios.delete("/1.0.0/business/keypartner-picture/" + id).catch(
      (err) => {
        console.log(err);
      }
    );
  };
};
