import axios from "axios";
import { USERS_ACTIONS } from "../constants/action-types";

export const getAllUsers = () => {
  return async (dispatch) => {
    dispatch({ type: USERS_ACTIONS.USERS_LOADING });
    await axios
      .get("/1.0.0/users", {
        //headers: { Authorization: token },
      })
      .then((response) => {
        dispatch({ type: USERS_ACTIONS.USERS_LOADED, payload: response.data });
      })
      .catch((err) => {
        dispatch({
          type: USERS_ACTIONS.USERS_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const getAllGroups = () => {
  return async (dispatch) => {
    dispatch({ type: USERS_ACTIONS.GROUPS_LOADING });
    await axios
      .get("/1.0.0/groups", {
        //headers: { Authorization: token },
      })
      .then((response) => {
        dispatch({ type: USERS_ACTIONS.GROUPS_LOADED, payload: response.data });
      })
      .catch((err) => {
        dispatch({
          type: USERS_ACTIONS.GROUPS_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const registerUser = (user) => {
  return async (dispatch) => {
    dispatch({ type: USERS_ACTIONS.USER_REGISTER_START });
    await axios
      .post("/1.0.0/users/registration", user, {
        //headers: { Authorization: token },
      })
      .then(() => {
        dispatch({ type: USERS_ACTIONS.USER_REGISTER_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: USERS_ACTIONS.USER_REGISTER_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const modifyUser = (user) => {
  return async (dispatch) => {
    dispatch({ type: USERS_ACTIONS.USER_REGISTER_START });
    await axios
      .post("/1.0.0/users/modify-user", user, {
        //headers: { Authorization: token },
      })
      .then(() => {
        dispatch({ type: USERS_ACTIONS.USER_REGISTER_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: USERS_ACTIONS.USER_REGISTER_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const deleteUser = (userId) => {
  return async () => {
    await axios.delete("/1.0.0/users/" + userId).catch((err) => {
      console.log(err.message);
    });
  };
};
