import React, { Component } from "react";
import { routes } from "../../constants/routes";
import { Route, Switch } from "react-router";
import MissingRight from "./missing-right";
import ProtectedRoute from "../ProtectedRoute/protected-route";

export default class Body extends Component {
  render() {
    return (
      <div>
        <Switch>
          {routes.map((current) => {
            return (
              <ProtectedRoute
                path={current.path}
                component={current.component}
                key={current.path}
                rights={current.rights}
              />
            );
          })}
          <Route path="/pf/missing-right" component={MissingRight} />
        </Switch>
      </div>
    );
  }
}
