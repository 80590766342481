import React, { useEffect } from "react";
import {
  Redirect,
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import Login from "./components/Login/login";
import Main from "./components/skeleton/main";
import ProtectedRoute from "./components/ProtectedRoute/protected-route";
import { Helmet } from "react-helmet";
import axios from "axios";
import "./App.css";

const App = () => {
  useEffect(() => {
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
  }, []);

  return (
    <div>
      <Helmet>
        <title>Petfriend - Admin</title>
      </Helmet>
      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <ProtectedRoute path="/pf" component={Main} /* rights={[1, 2]}  */ />
          <Redirect from="**" to="/login" />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
