import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  Table,
  Popconfirm,
  Checkbox,
} from "antd";
import React, { Component } from "react";
import Highlighter from "react-highlight-words";
import { connect } from "react-redux";
import Dragger from "antd/lib/upload/Dragger";
import {
  InboxOutlined,
  SearchOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  getBusinessCategories,
  createBusinessCategory,
  modifyBusinessCategory,
  modifyBusinessCategoryIndex,
  deleteBusinessCategory,
} from "../../actions/business";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { MenuOutlined } from "@ant-design/icons";
import "./businessCategory.css";

const mapDispatchToProps = (dispatch) => {
  return {
    getBusinessCategories: () => dispatch(getBusinessCategories()),
    createBusinessCategory: (params) =>
      dispatch(createBusinessCategory(params)),
    modifyBusinessCategory: (params) =>
      dispatch(modifyBusinessCategory(params)),
    modifyBusinessCategoryIndex: (params) =>
      dispatch(modifyBusinessCategoryIndex(params)),
    deleteBusinessCategory: (categoryId) =>
      dispatch(deleteBusinessCategory(categoryId)),
  };
};

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
};

class ConnectedBusinessCategory extends Component {
  formRef = React.createRef();

  state = {
    categories: [],
    id: null,
    name: "",
    contactInfo: "",
    file: [],
    picture: [],
    modify: false,
    openHoursNeeded: true,
  };

  componentDidMount = async () => {
    await this.props.getBusinessCategories();
    this.setState({ categories: this.props.categories });
    this.formRef.current.setFieldsValue({
      openHoursNeeded: true,
    });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeOpenHoursNeeded = () => {
    this.setState({ openHoursNeeded: !this.state.openHoursNeeded });
  };

  submitForm = async () => {
    const formData = new FormData();
    formData.set("name", this.state.name);
    formData.set("contactInfo", this.state.contactInfo);
    formData.set("openHoursNeeded", this.state.openHoursNeeded);
    if (this.state.file[0]) {
      formData.append("file", this.state.file[0]);
    }
    if (this.state.picture[0]) {
      formData.append("picture", this.state.picture[0]);
    }
    if (this.state.modify) {
      formData.set("id", this.state.id);
      await this.props.modifyBusinessCategory(formData);
    } else {
      await this.props.createBusinessCategory(formData);
    }
    if (this.props.saveStatus) {
      this.formRef.current.resetFields();
      this.setState({
        name: "",
        file: [],
        modify: false,
        openHoursNeeded: true,
      });
      this.formRef.current.setFieldsValue({
        openHoursNeeded: true,
      });
      await this.props.getBusinessCategories();
      this.setState({ categories: this.props.categories });
    }
  };

  removeFile = async () => {
    this.setState({ file: [] });
  };

  beforeUpload = (file) => {
    this.setState({ file: [file] });
    return false;
  };
  removePicture = async () => {
    this.setState({ picture: [] });
  };

  beforeUploadPicture = (file) => {
    this.setState({ picture: [file] });
    return false;
  };

  getColumnSearchProps = (dataIndex, searchLabel) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Keresés: ${searchLabel}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Keresés
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 100 }}
          >
            Visszaállítás
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text, record) =>
      this.state.searchedColumn === dataIndex ? (
        <Space align="center">
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        </Space>
      ) : (
        <Space align="center">{text}</Space>
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  handleModify = (record) => {
    this.setState({
      modify: true,
      contactInfo: record.contactInfo === null ? "" : record.contactInfo,
      name: record.name === null ? "" : record.name,
      id: record.id,
      openHoursNeeded: record.openHoursNeeded,
    });
    this.formRef.current.setFieldsValue({
      name: record.name,
      contactInfo: record.contactInfo,
      openHoursNeeded: record.openHoursNeeded,
    });
  };

  handleCancel = () => {
    this.setState({
      modify: false,
      name: "",
      contactInfo: "",
      id: null,
    });
    this.formRef.current.resetFields();
  };

  handleDelete = async (record) => {
    await this.props.deleteBusinessCategory(record.id);
    if (this.props.saveStatus) {
      await this.props.getBusinessCategories();
    }
  };

  onSortEnd = async ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        this.state.categories.slice(),
        oldIndex,
        newIndex
      )
        .filter((el) => !!el)
        .map((x, i) => {
          x.index = i;
          return x;
        });
      console.log("Sorted items: ", newData);
      this.setState({ categories: newData });
      const toSave = {
        categories: newData,
      };
      console.log(toSave);
      await this.props.modifyBusinessCategoryIndex(toSave);
    }
  };

  render() {
    const DraggableContainer = (props) => (
      <SortableBody
        useDragHandle
        disableAutoscroll
        helperClass="row-dragging"
        onSortEnd={this.onSortEnd}
        {...props}
      />
    );

    const DraggableBodyRow = ({ className, style, ...restProps }) => {
      // function findIndex base on Table rowKey props and should always be a right array index
      const index = this.state.categories.findIndex(
        (x) => x.index === restProps["data-row-key"]
      );
      return <SortableItem index={index} {...restProps} />;
    };
    const DragHandle = SortableHandle(() => (
      <MenuOutlined
        style={{
          cursor: "grab",
          color: "#999",
        }}
      />
    ));
    const columns = [
      {
        title: "Sorrend",
        dataIndex: "sort",
        width: 30,
        className: "drag-visible",
        render: () => <DragHandle />,
      },
      {
        title: "Üzleti Kategória",
        key: "name",
        dataIndex: "name",
        ...this.getColumnSearchProps("name", "Kategória neve"),
      },
      {
        title: "Kép",
        dataIndex: "picture",
        width: 50,
        render: (string, record) => (
          <Space size="middle">
            {record.picture && (
              <img
                alt={record.picture}
                src={`${process.env.REACT_APP_BASE_URL}2.0.0/business/category-picture/${record.picture}`}
                style={{ maxWidth: "50px" }}
              />
            )}
          </Space>
        ),
      },
      {
        title: "Módosítás",
        key: "modify",
        render: (string, record) => {
          return (
            <Space size="middle">
              <Button type="primary" onClick={() => this.handleModify(record)}>
                Módosítás
              </Button>
            </Space>
          );
        },
      },
      {
        title: "Törlés",
        key: "delete",
        render: (string, record) => {
          return (
            <Space size="middle">
              <Popconfirm
                title="Biztosan törölni szeretnéd a kategóriát?"
                okText="Igen"
                cancelText="Nem"
                onConfirm={() => this.handleDelete(record)}
                okButtonProps={{ danger: true }}
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              >
                <Button type="primary" danger>
                  Törlés
                </Button>
              </Popconfirm>
            </Space>
          );
        },
      },
    ];
    const SortableItem = SortableElement((props) => <tr {...props} />);
    const SortableBody = SortableContainer((props) => <tbody {...props} />);
    return (
      <div>
        <div>
          <h1>Üzleti kategóriák</h1>
        </div>
        <Row>
          <Col span={12} style={{ paddingRight: "0.5em" }}>
            <Table
              columns={columns}
              dataSource={this.state.categories}
              rowKey="index"
              pagination={{
                position: ["bottomCenter"],
                showSizeChanger: false,
                pageSize: 10,
                hideOnSinglePage: true,
              }}
              components={{
                body: {
                  wrapper: DraggableContainer,
                  row: DraggableBodyRow,
                },
              }}
            />
          </Col>
          <Col span={12} style={{ paddingLeft: "0.5em" }}>
            <Form
              ref={this.formRef}
              {...formItemLayout}
              name="register"
              onFinish={this.submitForm}
              scrollToFirstError
              layout="vertical"
            >
              <Form.Item
                label="Név"
                required
                rules={[
                  { required: true, message: "A mező kitöltése kötelező!" },
                ]}
                name="name"
              >
                <Input
                  name="name"
                  placeholder="Név"
                  onChange={this.handleChange}
                  value={this.state.name}
                />
              </Form.Item>
              <Form.Item
                label="Kapcsolat"
                /* required
                rules={[
                  { required: true, message: "A mező kitöltése kötelező!" },
                ]} */
                name="contactInfo"
              >
                <Input
                  name="contactInfo"
                  placeholder="Kapcsolat"
                  onChange={this.handleChange}
                  value={this.state.contactInfo}
                />
              </Form.Item>
              <Form.Item
                label="Nyitvatartás szükséges"
                name="openHoursNeeded"
                valuePropName="checked"
              >
                <Checkbox
                  name="openHoursNeeded"
                  onChange={this.handleChangeOpenHoursNeeded}
                  checked={this.state.openHoursNeeded}
                />
              </Form.Item>
              <Form.Item
                name="file"
                label="Pin"
                rules={[
                  {
                    required: false,
                    message: "Kérem adjon meg egy fájlt!",
                  },
                ]}
              >
                <Dragger
                  name="file"
                  multiple={false}
                  onRemove={this.removeFile}
                  accept=".png,.jpg,.jpeg,.gif"
                  beforeUpload={this.beforeUpload}
                  fileList={this.state.file}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Fájl feltöltéshez húzza be a mezőbe a fájlt vagy kattintson
                    ide
                  </p>
                </Dragger>
              </Form.Item>
              <Form.Item
                name="picture"
                label="Kép"
                rules={[
                  {
                    required: false,
                    message: "Kérem adjon meg egy fájlt!",
                  },
                ]}
              >
                <Dragger
                  name="picture"
                  multiple={false}
                  onRemove={this.removePicture}
                  accept=".png,.jpg,.jpeg,.gif"
                  beforeUpload={this.beforeUploadPicture}
                  fileList={this.state.picture}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Fájl feltöltéshez húzza be a mezőbe a fájlt vagy kattintson
                    ide
                  </p>
                </Dragger>
              </Form.Item>
              <Form.Item style={{ textAlign: "right", marginBottom: "0" }}>
                {this.state.modify && (
                  <Button
                    style={{ marginRight: "0.5em" }}
                    onClick={this.handleCancel}
                  >
                    Mégse
                  </Button>
                )}
                <Button type="primary" htmlType="submit">
                  Mentés
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  categories: state.businessReducer.businessCategories,
  status: state.businessReducer.status,
  saveStatus: state.businessReducer.saveStatus,
});

const BusinessCategory = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedBusinessCategory);
export default BusinessCategory;
