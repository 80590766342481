import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

class ConnectedProtectedRoute extends Component {
  isAuth = () => {
    if (this.props.token !== null) {
      return true;
    } else {
      return false;
    }
  };

  hasRight = () => {
    if (this.props.rights) {
      if (this.props.rights.includes(this.props.user.groupId)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.isAuth() ? (
          this.hasRight() ? (
            <Route path={this.props.path} component={this.props.component} />
          ) : (
            <Redirect from="**" to="/pf/missing-right" />
          )
        ) : (
          <Redirect from="**" to="/login" />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.loginReducer.token,
  user: state.loginReducer.user,
});

const ProtectedRoute = connect(mapStateToProps, null)(ConnectedProtectedRoute);
export default ProtectedRoute;
