import { DeleteFilled } from "@ant-design/icons";
import { Button, Col, Popconfirm, Row, Space, Table } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getComplaints, deleteComplaint } from "../../actions/business";

const mapDispatchToProps = (dispatch) => {
  return {
    getComplaints: () => dispatch(getComplaints()),
    deleteComplaint: (id) => dispatch(deleteComplaint(id)),
  };
};

class ConnectedComplaints extends Component {
  state = {
    selectedRow: {},
    detailsVisible: false,
  };

  componentDidMount = async () => {
    await this.props.getComplaints();
  };

  handleClickRow = (data, event) => {
    event.stopPropagation();
    event.preventDefault();
    this.setState({ selectedRow: data, detailsVisible: true });
  };

  handleCloseDetails = () => {
    this.setState({ selectedRow: {}, detailsVisible: false });
  };

  handleDeleteComplaint = async (data, event) => {
    event.stopPropagation();
    event.preventDefault();
    await this.props.deleteComplaint(data.id);
    await this.props.getComplaints();
  };

  render() {
    const columns = [
      { title: "Teljes név", dataIndex: "fullName", key: "fullName" },
      { title: "E-mail cím", dataIndex: "email", key: "email" },
      {
        title: "Panasz",
        dataIndex: "complaint",
        key: "complaint",
        ellipsis: true,
      },
      {
        title: "Törlés",
        key: "delete",
        render: (string, data) => {
          return (
            <Space size="middle">
              <Popconfirm
                title="Biztosan törölni szeretné a panaszt?"
                okText="Igen"
                cancelText="Nem"
                onConfirm={(event) => this.handleDeleteComplaint(data, event)}
              >
                <Button
                  danger
                  type="primary"
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                >
                  <DeleteFilled />
                </Button>
              </Popconfirm>
            </Space>
          );
        },
      },
    ];
    return (
      <div>
        <h1>Panaszok</h1>
        <Row>
          <Col span={24}>
            <Table
              rowKey="id"
              columns={columns}
              dataSource={this.props.complaints}
              pagination={{
                position: ["bottomCenter"],
                showSizeChanger: false,
                pageSize: 10,
                hideOnSinglePage: true,
              }}
              onRow={(data) => {
                return {
                  style: { cursor: "pointer" },
                  onClick: (event) => this.handleClickRow(data, event),
                };
              }}
            />
          </Col>
        </Row>
        <Modal
          centered
          footer={null}
          title="Panasz részletei"
          visible={this.state.detailsVisible}
          onCancel={this.handleCloseDetails}
        >
          <div>
            <div>
              <div>
                <strong>Teljes név:</strong>
              </div>
              <div>{this.state.selectedRow.fullName}</div>
            </div>
            <div>
              <div>
                <strong>E-mail cím:</strong>
              </div>
              <div>{this.state.selectedRow.email}</div>
            </div>
            <div>
              <div>
                <strong>Panasz:</strong>
              </div>
              <div>{this.state.selectedRow.complaint}</div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  complaints: state.businessReducer.complaints,
  status: state.businessReducer.status,
  saveStatus: state.businessReducer.saveStatus,
});

const Complaints = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedComplaints);
export default Complaints;
