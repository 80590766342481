import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import loginReducer from "../reducers/login";
import usersReducer from "../reducers/users";
import businessReducer from "../reducers/business";

const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: ["usersReducer", "businessReducer"],
};

const rootReducer = combineReducers({
  loginReducer,
  usersReducer,
  businessReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export const persistor = persistStore(store);
