import {
  DeleteFilled,
  EditFilled,
  InboxOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Row,
  Table,
  Form,
  Input,
  TimePicker,
  Space,
  Select,
  Checkbox,
} from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getBusiness,
  saveBusiness,
  getCategories,
  modifyBusiness,
  deleteBusiness,
  deleteLogo,
  deleteKeyPartnerPicture,
  getBusinessCategories,
} from "../../actions/business";
import moment from "moment";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import Dragger from "antd/lib/upload/Dragger";
import Highlighter from "react-highlight-words";
import * as path from "path";
const { Option } = Select;

const mapDispatchToProps = (dispatch) => {
  return {
    getBusiness: () => dispatch(getBusiness()),
    saveBusiness: (params) => dispatch(saveBusiness(params)),
    modifyBusiness: (params) => dispatch(modifyBusiness(params)),
    getCategories: () => dispatch(getCategories()),
    getBusinessCategories: () => dispatch(getBusinessCategories()),
    deleteBusiness: (id) => dispatch(deleteBusiness(id)),
    deleteLogo: (id) => dispatch(deleteLogo(id)),
    deleteKeyPartnerPicture: (id) => dispatch(deleteKeyPartnerPicture(id)),
  };
};

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
};

class ConnectedBusiness extends Component {
  formRef = React.createRef();

  state = {
    newBusinessVisible: false,
    monday: ["", ""],
    tuesday: ["", ""],
    wednesday: ["", ""],
    thursday: ["", ""],
    friday: ["", ""],
    saturday: ["", ""],
    sunday: ["", ""],
    name: "",
    latitude: 0.0,
    longitude: 0.0,
    city: "",
    zip: "",
    address: "",
    selectedId: null,
    modify: false,
    comment: "",
    defaultLat: 47.497913,
    defaultLng: 19.040236,
    file: [],
    keyPartnerPicture: [],
    selectedRecord: null,
    keyPartner: false,
    businessCategoryId: null,
    categories: [],
    businessDetails: "",
    phoneNumber: "",
    webPage: "",
    instagram: "",
    facebook: "",
    timeReservationURL: "",
    selectedBusinessCategory: null,
  };

  componentDidMount = async () => {
    await Promise.all([
      this.props.getBusiness(),
      this.props.getCategories(),
      this.props.getBusinessCategories(),
    ]);
    navigator.geolocation.getCurrentPosition((pos) => {
      this.setState({
        defaultLat: pos.coords.latitude,
        latitude: pos.coords.latitude,
        defaultLng: pos.coords.longitude,
        longitude: pos.coords.longitude,
      });
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.businessCategoryId !== this.state.businessCategoryId) {
      if (this.state.businessCategoryId === null) {
        this.setState({ selectedBusinessCategory: null });
      } else {
        const bcategory = this.props.businessCategories.find(
          (e) => e.id === this.state.businessCategoryId
        );
        this.setState({ selectedBusinessCategory: bcategory });
      }
    }
  };

  removeFile = async () => {
    this.setState({ file: [] });
    if (this.state.modify === true) {
      await this.props.deleteLogo(this.state.selectedId);
      await this.props.getBusiness();
    }
  };

  beforeUpload = (file) => {
    this.setState({ file: [file] });
    return false;
  };

  removeKeyPartnerPicture = async () => {
    this.setState({ keyPartnerPicture: [] });
    if (this.state.modify === true) {
      await this.props.deleteKeyPartnerPicture(this.state.selectedId);
      await this.props.getBusiness();
    }
  };

  beforeUploadKeyPartnerPicture = (file) => {
    this.setState({ keyPartnerPicture: [file] });
    return false;
  };

  openNewBusiness = () => {
    this.setState({
      newBusinessVisible: true,
      latitude: this.state.defaultLat,
      longitude: this.state.defaultLng,
    });
    this.formRef.current.setFieldsValue({
      latitude: this.state.defaultLat,
      longitude: this.state.defaultLng,
    });
  };

  closeNewBusiness = () => {
    this.setState({
      newBusinessVisible: false,
      address: "",
      zip: "",
      city: "",
      latitude: this.state.defaultLat,
      longitude: this.state.defaultLng,
      name: "",
      monday: ["", ""],
      tuesday: ["", ""],
      wednesday: ["", ""],
      thursday: ["", ""],
      friday: ["", ""],
      saturday: ["", ""],
      sunday: ["", ""],
      categories: [],
      comment: "",
      modify: false,
      selectedId: null,
      file: [],
      selectedRecord: null,
      keyPartner: false,
      businessCategoryId: null,
      businessDetails: "",
      phoneNumber: "",
      webPage: "",
      instagram: "",
      facebook: "",
      timeReservationURL: "",
    });
    this.formRef.current.resetFields();
    this.formRef.current.setFieldsValue({
      latitude: this.state.defaultLat,
      longitude: this.state.defaultLng,
    });
  };

  handleChange = (event) => {
    if (event.target.name === "longitude" || event.target.name === "latitude") {
      this.setState({ [event.target.name]: parseFloat(event.target.value) });
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  handleChangeCheckbox = (event) => {
    this.setState({ [event.target.name]: !this.state[event.target.name] });
  };

  submitForm = async () => {
    const toSave = {
      name: this.state.name,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
      zip: this.state.zip,
      city: this.state.city,
      address: this.state.address,
      categories: this.state.categories,
      comment: this.state.comment,
      keyPartner: this.state.keyPartner,
      businessCategoryId: this.state.businessCategoryId,
      businessDetails: this.state.businessDetails,
      phoneNumber: this.state.phoneNumber,
      webPage: this.state.webPage,
      instagram: this.state.instagram,
      facebook: this.state.facebook,
      timeReservationURL: this.state.timeReservationURL,
      //logo: this.state.file[0],
      openHours: this.state.selectedBusinessCategory.openHoursNeeded
        ? [
            {
              day: "Hétfő",
              index: 1,
              open: this.state.monday[0],
              close: this.state.monday[1],
            },
            {
              day: "Kedd",
              index: 2,
              open: this.state.tuesday[0],
              close: this.state.tuesday[1],
            },
            {
              day: "Szerda",
              index: 3,
              open: this.state.wednesday[0],
              close: this.state.wednesday[1],
            },
            {
              day: "Csütörtök",
              index: 4,
              open: this.state.thursday[0],
              close: this.state.thursday[1],
            },
            {
              day: "Péntek",
              index: 5,
              open: this.state.friday[0],
              close: this.state.friday[1],
            },
            {
              day: "Szombat",
              index: 6,
              open: this.state.saturday[0],
              close: this.state.saturday[1],
            },
            {
              day: "Vasárnap",
              index: 7,
              open: this.state.sunday[0],
              close: this.state.sunday[1],
            },
          ]
        : undefined,
    };
    if (this.state.modify) {
      toSave.id = this.state.selectedId;
      const ObjectToSave = JSON.stringify(toSave);
      const formData = new FormData();
      formData.set("data", ObjectToSave);
      if (
        this.state.file.length > 0 &&
        this.state.file[0].name !== this.state.selectedRecord.logo
      ) {
        formData.append("file", this.state.file[0]);
      }
      if (
        this.state.keyPartnerPicture.length > 0 &&
        this.state.keyPartnerPicture[0].name !==
          this.state.selectedRecord.keyPartnerPicture
      ) {
        formData.append("keyPartnerPicture", this.state.keyPartnerPicture[0]);
      }
      await this.props.modifyBusiness(formData);
    } else {
      const ObjectToSave = JSON.stringify(toSave);
      const formData = new FormData();
      formData.set("data", ObjectToSave);
      if (this.state.file.length > 0) {
        formData.append("file", this.state.file[0]);
      }
      if (this.state.keyPartnerPicture.length > 0) {
        formData.append("keyPartnerPicture", this.state.keyPartnerPicture[0]);
      }
      await this.props.saveBusiness(formData);
    }
    if (this.props.saveStatus) {
      this.closeNewBusiness();
    }
    await this.props.getBusiness();
  };

  getOpenHoursStateValue = (day) => {
    if (day !== undefined) {
      return [
        day.open === null ? "" : day.open.slice(0, -3),
        day.close === null ? "" : day.close.slice(0, -3),
      ];
    } else {
      return ["", ""];
    }
  };

  getOpenHoursFormValue = (day) => {
    if (day !== undefined) {
      return [
        day.open === null ? "" : moment(day.open.slice(0, -3), "HH:mm"),
        day.close === null ? "" : moment(day.close.slice(0, -3), "HH:mm"),
      ];
    } else {
      return ["", ""];
    }
  };

  handleClickModify = async (record) => {
    const monday = record.openHours.find((hour) => hour.day === "Hétfő");
    const tuesday = record.openHours.find((hour) => hour.day === "Kedd");
    const wednesday = record.openHours.find((hour) => hour.day === "Szerda");
    const thursday = record.openHours.find((hour) => hour.day === "Csütörtök");
    const friday = record.openHours.find((hour) => hour.day === "Péntek");
    const saturday = record.openHours.find((hour) => hour.day === "Szombat");
    const sunday = record.openHours.find((hour) => hour.day === "Vasárnap");
    const categories = record.categories.map((category) => category.id);
    const date = new Date();

    this.setState({
      selectedRecord: record,
      selectedId: record.id,
      address: record.address.address,
      zip: record.address.zip,
      city: record.address.city,
      name: record.name,
      longitude: record.longitude,
      latitude: record.latitude,
      categories: categories,
      comment: record.note,
      keyPartner: record.keyPartner,
      businessCategoryId: record.businessCategoryId,
      businessDetails: record.businessDetails,
      phoneNumber: record.phoneNumber,
      webPage: record.webPage,
      instagram: record.instagram,
      facebook: record.facebook,
      file:
        record.logo !== null
          ? [
              {
                name: record.logo,
                type: `image/${path.extname(record.logo).split(".")[1]}`,
                lastModified: date.getTime(),
                lastModifiedDate: date,
              },
            ]
          : [],
      keyPartnerPicture:
        record.keyPartnerPicture !== null
          ? [
              {
                name: record.keyPartnerPicture,
                type: `image/${
                  path.extname(record.keyPartnerPicture).split(".")[1]
                }`,
                lastModified: date.getTime(),
                lastModifiedDate: date,
              },
            ]
          : [],
      monday: this.getOpenHoursStateValue(monday),
      tuesday: this.getOpenHoursStateValue(tuesday),
      wednesday: this.getOpenHoursStateValue(wednesday),
      thursday: this.getOpenHoursStateValue(thursday),
      friday: this.getOpenHoursStateValue(friday),
      saturday: this.getOpenHoursStateValue(saturday),
      sunday: this.getOpenHoursStateValue(sunday),
      newBusinessVisible: true,
      modify: true,
    });

    this.formRef.current.setFieldsValue({
      address: record.address.address,
      zip: record.address.zip,
      city: record.address.city,
      name: record.name,
      longitude: record.longitude,
      latitude: record.latitude,
      categories: categories,
      comment: record.comment,
      keyPartner: record.keyPartner,
      businessCategory: record.businessCategoryId,
      businessDetails: record.businessDetails,
      phoneNumber: record.phoneNumber,
      webPage: record.webPage,
      instagram: record.instagram,
      facebook: record.facebook,
      monday: this.getOpenHoursFormValue(monday),
      tuesday: this.getOpenHoursFormValue(tuesday),
      wednesday: this.getOpenHoursFormValue(wednesday),
      thursday: this.getOpenHoursFormValue(thursday),
      friday: this.getOpenHoursFormValue(friday),
      saturday: this.getOpenHoursFormValue(saturday),
      sunday: this.getOpenHoursFormValue(sunday),
    });
  };

  handleChangeTime = (moment, string, name) => {
    this.setState({ [name]: string });
  };

  changeCategories = (event, name) => {
    this.setState({ [name]: event });
  };

  handleDeleteBusiness = async (id) => {
    await this.props.deleteBusiness(id);
    await this.props.getBusiness();
  };

  changeMarker = (event) => {
    this.setState({
      longitude: event.latLng.lng(),
      latitude: event.latLng.lat(),
    });
    this.formRef.current.setFieldsValue({
      longitude: event.latLng.lng(),
      latitude: event.latLng.lat(),
    });
  };

  getColumnSearchProps = (dataIndex, string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Keresés: ${string}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Keresés
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Visszaállítás
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {
    const columns = [
      {
        title: "Logó",
        dataIndex: "logo",
        width: 45,
        render: (string, record) => (
          <Space size="middle">
            {record.logo && (
              <img
                alt={record.logo}
                src={`${process.env.REACT_APP_BASE_URL}2.0.0/business/logo/${record.logo}`}
                style={{ maxWidth: "45px" }}
              />
            )}
          </Space>
        ),
      },
      {
        title: "Név",
        dataIndex: "name",
        key: "name",
        ...this.getColumnSearchProps("name", "Üzlet neve"),
      },
      {
        title: "Módosítás",
        key: "modify",
        render: (string, record) => {
          return (
            <Space size="middle">
              <Button
                type="primary"
                onClick={() => this.handleClickModify(record)}
              >
                <EditFilled />
                Módosítás
              </Button>
            </Space>
          );
        },
      },
      {
        title: "Törlés",
        key: "delete",
        render: (string, record) => {
          return (
            <Space size="middle">
              <Button
                danger
                type="primary"
                onClick={() => this.handleDeleteBusiness(record.id)}
              >
                <DeleteFilled />
                Törlés
              </Button>
            </Space>
          );
        },
      },
    ];
    return (
      <div>
        <Row>
          <Col span={24}>
            <Button type="primary" onClick={this.openNewBusiness}>
              <PlusOutlined />
              Új üzlet
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={this.props.businesses}
              rowKey="id"
              pagination={{
                position: ["bottomCenter"],
                showSizeChanger: false,
                pageSize: 10,
                hideOnSinglePage: true,
              }}
            />
          </Col>
        </Row>
        <Modal
          visible={this.state.newBusinessVisible}
          centered
          title="Új üzlet"
          onCancel={this.closeNewBusiness}
          footer={[]}
          width={800}
          forceRender
        >
          <Form
            ref={this.formRef}
            {...formItemLayout}
            name="register"
            onFinish={this.submitForm}
            scrollToFirstError
            layout="vertical"
          >
            <Row>
              <Col span={12} style={{ padding: "0.5em" }}>
                <Form.Item
                  label="Név"
                  required
                  rules={[
                    { required: true, message: "A mező kitöltése kötelező!" },
                  ]}
                  name="name"
                >
                  <Input
                    name="name"
                    placeholder="Név"
                    onChange={this.handleChange}
                    value={this.state.name}
                  />
                </Form.Item>
                <Form.Item label="Kiemelt partner" name="keyPartner">
                  <Checkbox
                    name="keyPartner"
                    placeholder="Kiemelt partner"
                    onChange={this.handleChangeCheckbox}
                    checked={this.state.keyPartner}
                  />
                </Form.Item>
                <Row>
                  <Col span={12} style={{ paddingRight: "0.5em" }}>
                    <Form.Item label="Hosszúság" name="longitude">
                      <Input
                        name="longitude"
                        placeholder="Hosszúság"
                        onChange={this.handleChange}
                        value={this.state.longitude}
                        type="number"
                        step={0.01}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ paddingLeft: "0.5em" }}>
                    <Form.Item label="Szélesség" name="latitude">
                      <Input
                        name="latitude"
                        placeholder="Szélesség"
                        onChange={this.handleChange}
                        value={this.state.latitude}
                        type="number"
                        step={0.01}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  label="Koordináták"
                  required
                  rules={[
                    { required: true, message: "A mezők kitöltése kötelező!" },
                  ]}
                  name="name"
                >
                  <LoadScript
                    googleMapsApiKey="AIzaSyBkZmGjgxSCwz4_njMrxt-LcFtU2XaIGjY"
                    //libraries={["places"]}
                  >
                    <GoogleMap
                      mapContainerStyle={{ height: "250px", width: "100%" }}
                      zoom={13}
                      center={{
                        lat: this.state.latitude,
                        lng: this.state.longitude,
                      }}
                      onClick={this.changeMarker}
                    >
                      <Marker
                        position={{
                          lat: this.state.latitude,
                          lng: this.state.longitude,
                        }}
                      ></Marker>
                    </GoogleMap>
                  </LoadScript>
                  {/* <Input
                    name="longitude"
                    placeholder="Hosszúság"
                    onChange={this.handleChange}
                    value={this.state.longitude}
                    type="number"
                    step="0.1"
                  />
                  <Input
                    name="latitude"
                    placeholder="Szélesség"
                    onChange={this.handleChange}
                    style={{ marginTop: "2em" }}
                    value={this.state.latitude}
                    type="number"
                    step="0.1"
                  /> */}
                </Form.Item>
                <Form.Item
                  label="Irányítószám"
                  required
                  rules={[
                    { required: true, message: "A mező kitöltése kötelező!" },
                  ]}
                  name="zip"
                >
                  <Input
                    name="zip"
                    placeholder="Irányítószám"
                    onChange={this.handleChange}
                    value={this.state.zip}
                  />
                </Form.Item>
                <Form.Item
                  label="Város"
                  required
                  rules={[
                    { required: true, message: "A mező kitöltése kötelező!" },
                  ]}
                  name="city"
                >
                  <Input
                    name="city"
                    placeholder="Város"
                    onChange={this.handleChange}
                    value={this.state.city}
                  />
                </Form.Item>
                <Form.Item
                  label="Cím"
                  required
                  rules={[
                    { required: true, message: "A mező kitöltése kötelező!" },
                  ]}
                  name="address"
                >
                  <Input
                    name="address"
                    placeholder="Cím"
                    onChange={this.handleChange}
                    value={this.state.address}
                  />
                </Form.Item>
                <Form.Item label="Kategóriák" name="categories">
                  <Select
                    name="categories"
                    onChange={(event) =>
                      this.changeCategories(event, "categories")
                    }
                    mode="multiple"
                  >
                    {this.props.categories.map((category) => (
                      <Option key={category.id} value={category.id}>
                        {category.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Üzlet kategória"
                  required
                  rules={[
                    { required: true, message: "A mező kitöltése kötelező!" },
                  ]}
                  name="businessCategory"
                >
                  <Select
                    name="businessCategory"
                    onChange={(event) =>
                      this.changeCategories(event, "businessCategoryId")
                    }
                  >
                    {this.props.businessCategories.map((category) => (
                      <Option key={category.id} value={category.id}>
                        {category.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12} style={{ padding: "0.5em" }}>
                <Form.Item label="Leírás" name="businessDetails">
                  <Input.TextArea
                    rows={3}
                    name="businessDetails"
                    placeholder="Leírás"
                    onChange={this.handleChange}
                    value={this.state.businessDetails}
                  />
                </Form.Item>
                <Form.Item label="Telefonszám" name="phoneNumber">
                  <Input
                    name="phoneNumber"
                    placeholder="Telefonszám"
                    onChange={this.handleChange}
                    value={this.state.phoneNumber}
                  />
                </Form.Item>
                <Form.Item label="Weblap" name="webPage">
                  <Input
                    name="webPage"
                    placeholder="Weblap"
                    onChange={this.handleChange}
                    value={this.state.webPage}
                  />
                </Form.Item>
                <Form.Item label="Instagram" name="instagram">
                  <Input
                    name="instagram"
                    placeholder="Instagram"
                    onChange={this.handleChange}
                    value={this.state.instagram}
                  />
                </Form.Item>
                <Form.Item label="Facebook" name="facebook">
                  <Input
                    name="facebook"
                    placeholder="Facebook"
                    onChange={this.handleChange}
                    value={this.state.facebook}
                  />
                </Form.Item>
                <Form.Item label="Időpont foglalás" name="timeReservationURL">
                  <Input
                    name="timeReservationURL"
                    placeholder="Időpont foglalás"
                    onChange={this.handleChange}
                    value={this.state.timeReservationURL}
                  />
                </Form.Item>
                {(this.state.selectedBusinessCategory === null ||
                  this.state.selectedBusinessCategory.openHoursNeeded) && (
                  <>
                    <h3>Nyitvatartás</h3>
                    <Row>
                      <Col span={12} style={{ padding: "0.5em" }}>
                        <Form.Item
                          label="Hétfő"
                          rules={
                            [
                              /* {
                          required: false,
                          message: "A mező kitöltése kötelező!",
                        }, */
                            ]
                          }
                          name="monday"
                          initialValue={[
                            this.state.monday[0] === ""
                              ? ""
                              : moment(this.state.monday[0], "HH:mm"),
                            this.state.monday[1] === ""
                              ? ""
                              : moment(this.state.monday[1], "HH:mm"),
                          ]}
                        >
                          <TimePicker.RangePicker
                            showSecond={false}
                            placeholder={["Nyit", "Zár"]}
                            name="monday"
                            minuteStep={5}
                            showNow={false}
                            format="HH:mm"
                            order={false}
                            value={[
                              this.state.monday[0] === ""
                                ? ""
                                : moment(this.state.monday[0], "HH:mm"),
                              this.state.monday[1] === ""
                                ? ""
                                : moment(this.state.monday[1], "HH:mm"),
                            ]}
                            onChange={(moment, string) =>
                              this.handleChangeTime(moment, string, "monday")
                            }
                          />
                        </Form.Item>
                        <Form.Item
                          label="Kedd"
                          rules={
                            [
                              /*  {
                          required: false,
                          message: "A mező kitöltése kötelező!",
                        }, */
                            ]
                          }
                          name="tuesday"
                          initialValue={[
                            this.state.tuesday[0] === ""
                              ? ""
                              : moment(this.state.tuesday[0], "HH:mm"),
                            this.state.tuesday[1] === ""
                              ? ""
                              : moment(this.state.tuesday[1], "HH:mm"),
                          ]}
                        >
                          <TimePicker.RangePicker
                            showSecond={false}
                            placeholder={["Nyit", "Zár"]}
                            name="tuesday"
                            minuteStep={15}
                            showNow={false}
                            format="HH:mm"
                            order={false}
                            value={[
                              this.state.tuesday[0] === ""
                                ? ""
                                : moment(this.state.tuesday[0], "HH:mm"),
                              this.state.tuesday[1] === ""
                                ? ""
                                : moment(this.state.tuesday[1], "HH:mm"),
                            ]}
                            onChange={(moment, string) =>
                              this.handleChangeTime(moment, string, "tuesday")
                            }
                          />
                        </Form.Item>
                        <Form.Item
                          label="Szerda"
                          rules={
                            [
                              /*  {
                          required: false,
                          message: "A mező kitöltése kötelező!",
                        }, */
                            ]
                          }
                          name="wednesday"
                          initialValue={[
                            this.state.wednesday[0] === ""
                              ? ""
                              : moment(this.state.wednesday[0], "HH:mm"),
                            this.state.wednesday[1] === ""
                              ? ""
                              : moment(this.state.wednesday[1], "HH:mm"),
                          ]}
                        >
                          <TimePicker.RangePicker
                            showSecond={false}
                            placeholder={["Nyit", "Zár"]}
                            name="wednesday"
                            minuteStep={15}
                            showNow={false}
                            format="HH:mm"
                            order={false}
                            value={[
                              this.state.wednesday[0] === ""
                                ? ""
                                : moment(this.state.wednesday[0], "HH:mm"),
                              this.state.wednesday[1] === ""
                                ? ""
                                : moment(this.state.wednesday[1], "HH:mm"),
                            ]}
                            onChange={(moment, string) =>
                              this.handleChangeTime(moment, string, "wednesday")
                            }
                          />
                        </Form.Item>
                        <Form.Item
                          label="Csütörtök"
                          rules={
                            [
                              /* {
                          required: false,
                          message: "A mező kitöltése kötelező!",
                        }, */
                            ]
                          }
                          name="thursday"
                          initialValue={[
                            this.state.thursday[0] === ""
                              ? ""
                              : moment(this.state.thursday[0], "HH:mm"),
                            this.state.thursday[1] === ""
                              ? ""
                              : moment(this.state.thursday[1], "HH:mm"),
                          ]}
                        >
                          <TimePicker.RangePicker
                            showSecond={false}
                            placeholder={["Nyit", "Zár"]}
                            name="thursday"
                            minuteStep={15}
                            showNow={false}
                            format="HH:mm"
                            order={false}
                            value={[
                              this.state.thursday[0] === ""
                                ? ""
                                : moment(this.state.thursday[0], "HH:mm"),
                              this.state.thursday[1] === ""
                                ? ""
                                : moment(this.state.thursday[1], "HH:mm"),
                            ]}
                            onChange={(moment, string) =>
                              this.handleChangeTime(moment, string, "thursday")
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12} style={{ padding: "0.5em" }}>
                        <Form.Item
                          label="Péntek"
                          rules={
                            [
                              /* {
                          required: false,
                          message: "A mező kitöltése kötelező!",
                        }, */
                            ]
                          }
                          name="friday"
                          initialValue={[
                            this.state.friday[0] === ""
                              ? ""
                              : moment(this.state.friday[0], "HH:mm"),
                            this.state.friday[1] === ""
                              ? ""
                              : moment(this.state.friday[1], "HH:mm"),
                          ]}
                        >
                          <TimePicker.RangePicker
                            showSecond={false}
                            placeholder={["Nyit", "Zár"]}
                            name="friday"
                            minuteStep={15}
                            showNow={false}
                            format="HH:mm"
                            order={false}
                            value={[
                              this.state.friday[0] === ""
                                ? ""
                                : moment(this.state.friday[0], "HH:mm"),
                              this.state.friday[1] === ""
                                ? ""
                                : moment(this.state.friday[1], "HH:mm"),
                            ]}
                            onChange={(moment, string) =>
                              this.handleChangeTime(moment, string, "friday")
                            }
                          />
                        </Form.Item>
                        <Form.Item
                          label="Szombat"
                          rules={
                            [
                              /*  {
                          required: false,
                          message: "A mező kitöltése kötelező!",
                        }, */
                            ]
                          }
                          name="saturday"
                          initialValue={[
                            this.state.saturday[0] === ""
                              ? ""
                              : moment(this.state.saturday[0], "HH:mm"),
                            this.state.saturday[1] === ""
                              ? ""
                              : moment(this.state.saturday[1], "HH:mm"),
                          ]}
                        >
                          <TimePicker.RangePicker
                            showSecond={false}
                            placeholder={["Nyit", "Zár"]}
                            name="saturday"
                            minuteStep={15}
                            showNow={false}
                            format="HH:mm"
                            order={false}
                            value={[
                              this.state.saturday[0] === ""
                                ? ""
                                : moment(this.state.saturday[0], "HH:mm"),
                              this.state.saturday[1] === ""
                                ? ""
                                : moment(this.state.saturday[1], "HH:mm"),
                            ]}
                            onChange={(moment, string) =>
                              this.handleChangeTime(moment, string, "saturday")
                            }
                          />
                        </Form.Item>
                        <Form.Item
                          label="Vasárnap"
                          rules={
                            [
                              /* {
                          required: false,
                          message: "A mező kitöltése kötelező!",
                        }, */
                            ]
                          }
                          name="sunday"
                          initialValue={[
                            this.state.sunday[0] === ""
                              ? ""
                              : moment(this.state.sunday[0], "HH:mm"),
                            this.state.sunday[1] === ""
                              ? ""
                              : moment(this.state.sunday[1], "HH:mm"),
                          ]}
                        >
                          <TimePicker.RangePicker
                            showSecond={false}
                            placeholder={["Nyit", "Zár"]}
                            name="sunday"
                            minuteStep={15}
                            showNow={false}
                            format="HH:mm"
                            order={false}
                            value={[
                              this.state.sunday[0] === ""
                                ? ""
                                : moment(this.state.sunday[0], "HH:mm"),
                              this.state.sunday[1] === ""
                                ? ""
                                : moment(this.state.sunday[1], "HH:mm"),
                            ]}
                            onChange={(moment, string) =>
                              this.handleChangeTime(moment, string, "sunday")
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}
                <Row>
                  <Col span={12} style={{ padding: "0.5em" }}>
                    <Form.Item
                      name="file"
                      label="Logó"
                      rules={[
                        {
                          required: false,
                          message: "Kérem adjon meg egy fájlt!",
                        },
                      ]}
                    >
                      <Dragger
                        name="file"
                        multiple={false}
                        //onChange={this.changeUploadedFile}
                        onRemove={this.removeFile}
                        accept=".png,.jpg,.jpeg,.gif"
                        beforeUpload={this.beforeUpload}
                        fileList={this.state.file}
                      >
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                          Fájl feltöltéshez húzza be a mezőbe a fájlt vagy
                          kattintson ide
                        </p>
                      </Dragger>
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ padding: "0.5em" }}>
                    <Form.Item
                      name="comment"
                      label="Megjegyzés"
                      rules={[
                        {
                          required: false,
                          message: "Kérem adjon meg egy fájlt!",
                        },
                      ]}
                    >
                      <Input.TextArea
                        name="comment"
                        value={this.state.comment}
                        onChange={this.handleChange}
                        rows={7}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                {this.state.keyPartner && (
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="keyPartnerPicture"
                        label="Kiemelt partner kép"
                        rules={[
                          {
                            required: false,
                            message: "Kérem adjon meg egy fájlt!",
                          },
                        ]}
                      >
                        <Dragger
                          name="file"
                          multiple={false}
                          //onChange={this.changeUploadedFile}
                          onRemove={this.removeKeyPartnerPicture}
                          accept=".png,.jpg,.jpeg,.gif"
                          beforeUpload={this.beforeUploadKeyPartnerPicture}
                          fileList={this.state.keyPartnerPicture}
                        >
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p className="ant-upload-text">
                            Fájl feltöltéshez húzza be a mezőbe a fájlt vagy
                            kattintson ide
                          </p>
                        </Dragger>
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
            <Form.Item style={{ textAlign: "right", marginBottom: "0" }}>
              <Button type="primary" htmlType="submit">
                Mentés
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  businesses: state.businessReducer.businesses,
  status: state.businessReducer.status,
  saveStatus: state.businessReducer.saveStatus,
  categories: state.businessReducer.categories,
  businessCategories: state.businessReducer.businessCategories,
});

const Business = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedBusiness);
export default Business;
