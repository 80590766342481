import { SearchOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Popconfirm, Row, Space, Table } from "antd";
import React, { Component } from "react";
import Highlighter from "react-highlight-words";
import { connect } from "react-redux";
import {
  deleteCategory,
  getCategories,
  saveCategory,
} from "../../actions/business";

const mapDispatchToProps = (dispatch) => {
  return {
    getCategories: () => dispatch(getCategories()),
    deleteCategory: (id) => dispatch(deleteCategory(id)),
    saveCategory: (params) => dispatch(saveCategory(params)),
  };
};

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
};

class ConnectedCategory extends Component {
  formRef = React.createRef();

  state = {
    name: "",
  };

  componentDidMount = async () => {
    await this.props.getCategories();
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  submitForm = async () => {
    const toSave = { name: this.state.name };
    await this.props.saveCategory(toSave);
    if (this.props.saveStatus) {
      this.formRef.current.resetFields();
      this.setState({ name: "" });
      await this.props.getCategories();
    }
  };

  deleteCategory = async (id) => {
    await this.props.deleteCategory(id);
    if (this.props.saveStatus) {
      this.formRef.current.resetFields();
      this.setState({ name: "" });
      await this.props.getCategories();
    }
  };

  getColumnSearchProps = (dataIndex, searchLabel) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Keresés: ${searchLabel}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Keresés
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 100 }}
          >
            Visszaállítás
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text, record) =>
      this.state.searchedColumn === dataIndex ? (
        <Space align="center">
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        </Space>
      ) : (
        <Space align="center">{text}</Space>
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {
    const columns = [
      {
        title: "Kategória",
        key: "name",
        dataIndex: "name",
        ...this.getColumnSearchProps("name", "Kategória neve"),
      },
      {
        title: "Törlés",
        key: "delete",
        render: (string, record) => {
          return (
            <Space size="middle">
              <Popconfirm
                onConfirm={() => this.deleteCategory(record.id)}
                title="Biztosan törölni szeretné a kategóriát?"
                okText="Igen"
                cancelText="Nem"
              >
                <Button type="primary" danger>
                  Törlés
                </Button>
              </Popconfirm>
            </Space>
          );
        },
      },
    ];
    return (
      <div>
        <div>
          <h1>Kategóriák</h1>
        </div>
        <Row>
          <Col span={12} style={{paddingRight: "0.5em"}}>
            <Table
              columns={columns}
              dataSource={this.props.categories}
              rowKey="id"
              pagination={{
                position: ["bottomCenter"],
                showSizeChanger: false,
                pageSize: 10,
                hideOnSinglePage: true,
              }}
            />
          </Col>
          <Col span={12} style={{paddingLeft: "0.5em"}}>
            <Form
              ref={this.formRef}
              {...formItemLayout}
              name="register"
              onFinish={this.submitForm}
              scrollToFirstError
              layout="vertical"
            >
              <Form.Item
                label="Név"
                required
                rules={[
                  { required: true, message: "A mező kitöltése kötelező!" },
                ]}
                name="name"
              >
                <Input
                  name="name"
                  placeholder="Név"
                  onChange={this.handleChange}
                  value={this.state.name}
                />
              </Form.Item>
              <Form.Item style={{ textAlign: "right", marginBottom: "0" }}>
                <Button type="primary" htmlType="submit">
                  Mentés
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  categories: state.businessReducer.categories,
  status: state.businessReducer.status,
  saveStatus: state.businessReducer.saveStatus,
});

const Category = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedCategory);
export default Category;
