import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Select } from "antd";
import Axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getBusinessCategories } from "../../actions/business";

const { Option } = Select;

const mapDispatchToProps = (dispatch) => {
  return {
    getBusinessCategories: () => dispatch(getBusinessCategories()),
  };
};

class ConnectedConfig extends Component {
  state = {
    version: 1,
    pinVersion: 1,
    defaultIds: [],
  };

  componentDidMount = async () => {
    await this.getData();
  };

  getData = async () => {
    await Axios.get("/1.0.0/config").then((response) => {
      this.setState({
        version: response.data.contractVersions.termsAndConditions,
        pinVersion: response.data.contractVersions.pinVersion,
        defaultIds: response.data.mapConfig.defaultCategoryIds.map((e) =>
          e.toString()
        ),
      });
    });
    await this.props.getBusinessCategories();
  };

  handleClickPlusTermsAndConditions = async () => {
    await Axios.post("/1.0.0/config", {
      termsAndConditions: this.state.version + 1,
      pinVersion: this.state.pinVersion,
    });
    await this.getData();
  };

  handleClickMinusTermsAndConditions = async () => {
    await Axios.post("/1.0.0/config", {
      termsAndConditions: this.state.version - 1,
      pinVersion: this.state.pinVersion,
    });
    await this.getData();
  };

  handleClickPlusPinVersion = async () => {
    await Axios.post("/1.0.0/config", {
      termsAndConditions: this.state.version,
      pinVersion: this.state.pinVersion + 1,
    });
    await this.getData();
  };

  handleClickMinusPinVersion = async () => {
    await Axios.post("/1.0.0/config", {
      termsAndConditions: this.state.version,
      pinVersion: this.state.pinVersion - 1,
    });
    await this.getData();
  };

  handleChangeDefaultIds = async (event) => {
    await Axios.post("/2.0.0/config/default-category", {
      defaultIds: event.map((e) => parseInt(e)),
    });
    await this.getData();
  };

  render() {
    return (
      <div>
        <div>
          <label>Felhasználási feltételek verziója: </label>
          {this.state.version}
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ padding: "0.5em" }}>
            <Button
              disabled={this.state.version === 1}
              onClick={this.handleClickMinusTermsAndConditions}
            >
              <MinusOutlined />
            </Button>
          </div>
          <div style={{ padding: "0.5em" }}>
            <Button onClick={this.handleClickPlusTermsAndConditions}>
              <PlusOutlined />
            </Button>
          </div>
        </div>
        <div>
          <label>Pin-ek verziója: </label>
          {this.state.pinVersion}
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ padding: "0.5em" }}>
            <Button
              disabled={this.state.version === 1}
              onClick={this.handleClickMinusPinVersion}
            >
              <MinusOutlined />
            </Button>
          </div>
          <div style={{ padding: "0.5em" }}>
            <Button onClick={this.handleClickPlusPinVersion}>
              <PlusOutlined />
            </Button>
          </div>
        </div>
        <div>
          <label>Alapértelmezett kategóriák: </label>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ padding: "0.5em", width: "350px" }}>
            <Select
              mode="tags"
              value={this.state.defaultIds}
              onChange={this.handleChangeDefaultIds}
              style={{ width: "100%" }}
            >
              {this.props.businessCategories.map((c) => {
                return <Option key={c.id}>{c.name}</Option>;
              })}
            </Select>
          </div>
          <div style={{ padding: "0.5em" }}>
            <Button onClick={this.handleClickPlusPinVersion}>
              <PlusOutlined />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  complaints: state.businessReducer.complaints,
  status: state.businessReducer.status,
  saveStatus: state.businessReducer.saveStatus,
  businessCategories: state.businessReducer.businessCategories,
});

const Config = connect(mapStateToProps, mapDispatchToProps)(ConnectedConfig);
export default Config;
