/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Input, Button, Form, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import logo from "../../static/logo.png";
import "./login.scss";
import { connect } from "react-redux";
import { login } from "../../actions/login";

const mapDispatchToProps = (dispatch) => {
  return {
    login: (email, password) => dispatch(login(email, password)),
  };
};

class ConnectedLogin extends Component {
  formRef = React.createRef();

  state = {
    email: "",
    password: "",
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleFormSubmit = async () => {
    await this.props.login(this.state.email, this.state.password);
    if (this.props.status) {
      this.props.history.push("/pf/users");
      //message.success("Sikeres bejelentkezés!");
    } else {
      message.error("Helytelen e-mail cím vagy jelszó!", 5);
      this.formRef.current.resetFields();
      this.setState({ email: "", password: "" });
    }
  };

  render() {
    return (
      <div className="login-page-container">
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={this.handleFormSubmit}
          ref={this.formRef}
        >
          <div className="login-page-icon-container">
            <img src={logo} alt="Fireshield logo" className="login-page-icon" />
          </div>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Kérem adja meg E-mail címét!",
              },
              { type: "email", message: "Helytelen formátum!" },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="E-mail cím"
              name="email"
              autoComplete="off"
              value={this.state.email || ""}
              onChange={this.handleChange}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Kérem adja meg a jelszavát!" }]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Jelszó"
              name="password"
              value={this.state.password || ""}
              onChange={this.handleChange}
            />
          </Form.Item>
          {/* <Form.Item>
            {/* <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item> 
            <a className="login-form-forgot" href="">
              Elfelejtett jelszó
            </a>
          </Form.Item> */}

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Bejelentkezés
            </Button>
            {/* Or <a href="">register now!</a> */}
          </Form.Item>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.loginReducer.token,
  status: state.loginReducer.status,
});

const Login = connect(mapStateToProps, mapDispatchToProps)(ConnectedLogin);
export default Login;
