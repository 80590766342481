import React, { Component } from "react";
import Body from "./body";
import { Layout, Menu } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  LogoutOutlined,
  ShopOutlined,
  SelectOutlined,
  ExclamationCircleOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import "./main.scss";
import logo from "../../static/logo.png";
import { connect } from "react-redux";
import { logout } from "../../actions/login";
import { NavLink } from "react-router-dom";
import HttpService from "../../services/http";

const { Header, Sider, Content } = Layout;

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  };
};

class ConnectedMain extends Component {
  state = {
    collapsed: false,
    selectedKey: "1",
  };

  constructor(props) {
    super(props);
    HttpService.setAxiosDefaults(props.token);
  }

  componentDidMount = async () => {
    this.setDefaultSelectedKeyForMenu();
  };

  componentWillUnmount = () => {
    HttpService.clearToken();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setDefaultSelectedKeyForMenu();
    }
  }

  setDefaultSelectedKeyForMenu = () => {
    switch (this.props.location.pathname) {
      case "/pf/users":
        this.setState({ selectedKey: "1" });
        break;
      case "/pf/business":
        this.setState({ selectedKey: "2" });
        break;
      case "/pf/category":
        this.setState({ selectedKey: "3" });
        break;
      case "/pf/complaints":
        this.setState({ selectedKey: "5" });
        break;
      case "/pf/config":
        this.setState({ selectedKey: "6" });
        break;
      case "/pf/business-category":
        this.setState({ selectedKey: "7" });
        break;
      default:
        this.setState({ selectedKey: null });
        break;
    }
  };

  handleSelect = (event) => {
    this.setState({ selectedKey: event.key });
  };

  logout = async () => {
    await this.props.logout();
    this.props.history.push("/login");
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };
  render() {
    return (
      <div style={{ height: "100%" }}>
        <Layout style={{ minHeight: "100vh" }}>
          <Sider
            trigger={null}
            collapsible
            collapsed={this.state.collapsed}
            className={!this.state.collapsed && "fireshield-sider"}
          >
            <div
              className={
                this.state.collapsed
                  ? "logo-container logo-container-collapsed"
                  : "logo-container"
              }
            >
              <img src={logo} alt="logo" className="logo-icon" />
            </div>
            <Menu
              theme="dark"
              mode="inline"
              selectedKeys={[this.state.selectedKey]}
              onSelect={this.handleSelect}              
            >
              <Menu.Item key="1" icon={<UserOutlined />}>
                <NavLink to="/pf/users" className="link-color">
                  Felhasználók
                </NavLink>
              </Menu.Item>
              <Menu.Item key="2" icon={<ShopOutlined />}>
                <NavLink to="/pf/business" className="link-color">
                  Üzletek
                </NavLink>
              </Menu.Item>
              <Menu.Item key="3" icon={<SelectOutlined />}>
                <NavLink to="/pf/category" className="link-color">
                  Kategóriák
                </NavLink>
              </Menu.Item>
              <Menu.Item key="7" icon={<SelectOutlined />}>
                <NavLink to="/pf/business-category" className="link-color">
                  Üzleti Kategóriák
                </NavLink>
              </Menu.Item>
              <Menu.Item key="5" icon={<ExclamationCircleOutlined />}>
                <NavLink to="/pf/complaints" className="link-color">
                  Panaszok
                </NavLink>
              </Menu.Item>
              <Menu.Item key="6" icon={<SettingOutlined />}>
                <NavLink to="/pf/config" className="link-color">
                  Config
                </NavLink>
              </Menu.Item>
              <Menu.Item
                key="4"
                icon={<LogoutOutlined />}
                onClick={this.logout}
              >
                Kijelentkezés
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout className="site-layout">
            <Header
              className="site-layout-background"
              style={{ padding: "0 0 0 26px" }}
            >
              {React.createElement(
                this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: "trigger",
                  onClick: this.toggle,
                }
              )}
              {/* <Dropdown.Button
                overlay={this.renderMenu}
                icon={<UserOutlined />}
                style={{ float: "right", margin: "12px 26px 0 0" }}
              /> */}
            </Header>
            <Content
              className="site-layout-background"
              style={{
                margin: "24px 16px",
                padding: 24,
                height: "100%",
                //minHeight: 500,
              }}
            >
              <Body />
            </Content>
          </Layout>
        </Layout>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.loginReducer.token,
  user: state.loginReducer.user,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(ConnectedMain);
export default Main;
